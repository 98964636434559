import { Outlet } from 'react-router-dom';
import GlobalNavbar from '../global-navbar/navbar';
import Footer from 'src/components/Footer/Footer';

export default function MainLayout() {
  return (
    <>
      <GlobalNavbar />
      <Outlet />
      <Footer />
    </>
  );
}
