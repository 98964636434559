import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import { toast } from 'react-toastify';
import TextInput from 'src/components/TextInput/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../../../features/auth/authActions';
import { Link as RouterLink } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

export default function LoginForm() {
  const navigate = useNavigate();
  const { loading, error, userInfo, success } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const token = secureLocalStorage.getItem('userToken');

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (token) {
      toast.success(`Welcome back ${userInfo?.firstname || ''} ${userInfo?.lastname || ''}`);
      navigate('/dashboard', { replace: true });
    }
  }, [token, error, navigate]);

  const onSubmit = (data) => {
    if (data) {
      dispatch(userLogin(data));
    } else {
      toast.error('Invalid email or password!!');
    }
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{ email: '', password: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Email is required!';
          }
          if (!values.password) {
            errors.password = 'Password is required!';
          }
          if (!validateEmail(values.email)) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        render={({ handleSubmit, errors }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Field fullWidth name="email">
                {(props) => <TextInput label={'Email address'} {...props} />}
              </Field>

              <Field fullWidth name="password">
                {(props) => (
                  <TextInput
                    label={'Password'}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    {...props}
                  />
                )}
              </Field>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <Link
                variant="subtitle2"
                underline="hover"
                component={RouterLink}
                to="/auth/forgot-password"
                sx={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  color: '#03396c'
                }}
              >
                Forgot password?
              </Link>
            </Stack>
            <div className="buttons">
              <LoadingButton
                fullWidth
                loading={loading}
                size="large"
                type="submit"
                variant="contained"
              >
                Login
              </LoadingButton>
            </div>
          </form>
        )}
      />
    </>
  );
}
