import { createAsyncThunk } from '@reduxjs/toolkit';
import { baseURL } from 'src/constants/baseURL';
import api from '../auth/Api';

export const getAuctioneerUsers = createAsyncThunk(
  'user/get',
  async ({ auctioneerId, weekId }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/auctioneer/get-auctioneer-users`,
        { auctioneerId, weekId },
        config
      );
      if (data) {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const auctioneerCarsList = createAsyncThunk(
  'cars/get',
  async ({ auctioneer_id, user_id, weekId }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/auctioneer/auctioneer-vehicles-only`,
        { auctioneer_id, user_id, weekId },
        config
      );
      if (data) {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const sortAuctioneerVehicles = createAsyncThunk(
  'cars/sort-by-year',
  async ({ auctioneerId, weekId, orderDirection, sortBy }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/auctioneer/sort-auctioneer-vehicles`,
        { auctioneerId, weekId, orderDirection, sortBy },
        config
      );
      if (data) {
        return data?.data[0]?.vehicles_list;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getFilteredByConditionLight = createAsyncThunk(
  'auctioneer/filter-by-condition-light',
  async ({ auctioneerId, weekId }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/auctioneer/filter-by-condition-light`,
        { auctioneerId, weekId },
        config
      );
      if (data) {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getFilteredByAuction = createAsyncThunk(
  'cars/sorted-by-auction',
  async ({ auctioneerId, weekId }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/auctioneer/filter-by-auctioneer-auctions`,
        { auctioneerId, weekId },
        config
      );
      if (data) {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getFilteredBySaleStatus = createAsyncThunk(
  'cars/sorted-by-sale-status',
  async ({ auctioneerId, weekId }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/auctioneer/filter-by-sale-status`,
        { auctioneerId, weekId },
        config
      );
      if (data) {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getSoldVehciles = createAsyncThunk(
  'cars/sort-by-sale-status',
  async ({ auctioneerId, weekId }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/auctioneer/carCountBySaleStatus`,
        { auctioneerId, weekId },
        config
      );
      if (data) {
        return data?.data[0]?.sold_vehicles;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getNotSoldVehciles = createAsyncThunk(
  'cars/sort-by-sale-status',
  async ({ auctioneerId, weekId }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/auctioneer/carCountBySaleStatus`,
        { auctioneerId, weekId },
        config
      );
      if (data) {
        return data?.data[0]?.notsold_vehicles;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
