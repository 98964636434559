import { lazy, Suspense } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import { useSelector } from 'react-redux';
import MainLayout from './layouts/main/main-layout';
import LandingPage from './pages/LandingPage';
import Settings from './pages/Settings/Settings';
import Download from './pages/Download/Download';
import PricingPage from './pages/PricingPage';
import About from './pages/About';
import Contact from './pages/Contact';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import { CircularProgress } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';

const LoadSuspense = (Component) => (props) =>
  (
    <Suspense
      fallback={
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress color="inherit" size={60} />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );

const DashboardAppPage = LoadSuspense(lazy(() => import('./pages/DashboardAppPage')));
const OpportunityPage = LoadSuspense(lazy(() => import('./pages/Opportunity/Opportunities')));

const ProductsPage = LoadSuspense(lazy(() => import('./pages/ProductsPage')));
const AddProducts = LoadSuspense(lazy(() => import('./pages/Products/AddProducts')));
const ProductDetails = LoadSuspense(lazy(() => import('./pages/Products/ProductDetails')));
const EditProduct = LoadSuspense(lazy(() => import('./pages/Products/EditProduct')));

const AuctioneerUsers = LoadSuspense(lazy(() => import('./pages/Opportunity/AuctioneerUsers')));
/// Profile Lazy Page

const AuctionerProfile = LoadSuspense(lazy(() => import('./pages/AuctionerProfile')));

const ActivityPage = LoadSuspense(lazy(() => import('./pages/Activity')));
const PrivacyPolicyPage = LoadSuspense(lazy(() => import('./pages/PrivacePolicy')));
const TermsAndConditionsPage = LoadSuspense(lazy(() => import('./pages/TermsAndConditions')));
const PortfolioPage = LoadSuspense(lazy(() => import('./pages/PortfolioPage')));

export default function Router() {
  // const { userInfo, brandInfo } = useSelector((state) => state.auth);
  const isLogged = secureLocalStorage.getItem('userToken');

  const routes = useRoutes([
    {
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <LandingPage />
        },
        { path: '/download', element: <Download /> },
        { path: '/pricing', element: <PricingPage /> },
        { path: '/about', element: <About /> },
        { path: '/contact', element: <Contact /> },
        {
          path: '/dashboard',
          element: isLogged ? <DashboardLayout /> : <Navigate to={'/auth/login'} />,
          children: [
            {
              element: <Navigate to="/dashboard/app" />,
              index: true
            },
            { path: 'app', element: <DashboardAppPage /> },
            { path: 'opportunities', element: <OpportunityPage /> },
            { path: 'opportunities/users', element: <AuctioneerUsers /> },
            { path: 'opportunities/users/cars', element: <ProductsPage /> },
            { path: 'opportunities/users/cars/preview', element: <ProductDetails /> },
            { path: 'settings', element: <Settings /> },
            { path: 'settings/auctionerProfile', element: <AuctionerProfile /> },
            { path: 'settings/activity', element: <ActivityPage /> },
            { path: 'settings/portfolio', element: <PortfolioPage /> },
            { path: 'settings/privacy-policy', element: <PrivacyPolicyPage /> },
            { path: 'settings/terms-and-conditions', element: <TermsAndConditionsPage /> }
          ]
        },
        {
          path: '/auth',
          element: isLogged ? <Navigate to={'/dashboard'} /> : <Outlet />,
          children: [
            { path: 'login', element: <LoginPage /> },
            { path: 'forgot-password', element: <ForgotPassword /> },
            { path: 'reset-password/:token', element: <ResetPassword /> }
          ]
        }
      ]
    }
    // {
    //     element: <SimpleLayout />,
    //     children: [
    //         {
    //             element: <Navigate to="/dashboard/app" />,
    //             index: true,
    //         },
    //         { path: "404", element: <Page404 /> },
    //         { path: "*", element: <Navigate to="/404" /> },
    //     ],
    // },
    // {
    //     path: "*",
    //     element: <Navigate to="/404" replace />,
    // },
  ]);

  return routes;
}
