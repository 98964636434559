import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';

const heroBox = {
  width: '100%',
  display: 'flex',
  minHeight: '600px',
  alignItems: 'center',
  justifyContent: 'center'
};

const gridContainer = {
  display: 'flex',
  alignItems: 'center',
  maxWidth: '1400px',
  padding: '50px'
};

const About = () => {
  return (
    <Box style={heroBox}>
      <Grid container spacing={6} style={gridContainer}>
        <Grid item xs={12} md={7} style={{ paddingLeft: '0 !important' }}>
          <Typography variant="h3" fontWeight={700} style={{ paddingBottom: '15px' }}>
            About Agency
          </Typography>
          <Typography variant="subtitile2">
            At our agency, we believe in building long-term relationships with our clients and
            providing exceptional customer service. We are committed to helping you succeed and will
            work tirelessly to ensure your satisfaction. So, let us help you modernize and optimize
            your systems to achieve maximum efficiency and profitability.
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <img src={'/assets/images/myteam.jpg'} alt="App Preview" width={'100%'} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
