import { createSlice } from '@reduxjs/toolkit';
import {
  getAuctioneerUsers,
  auctioneerCarsList,
  sortAuctioneerVehicles,
  getFilteredByConditionLight,
  getFilteredByAuction,
  getSoldVehciles,
  getNotSoldVehciles,
  getFilteredBySaleStatus
} from './userActions';
const initialState = {
  loading: false,
  userData: null,
  auctioneerCars: null,
  soldVehicles: null,
  notSoldVehicles: null,
  redVehicles: null,
  yellowVehicles: null,
  greenVehicles: null,
  error: null,
  success: false
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setFilterType(state, action) {
      state.userData = { ...state.userData, filter_type: action.payload };
    }
  },
  extraReducers: {
    [getAuctioneerUsers.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAuctioneerUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = payload;
    },
    [getAuctioneerUsers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getFilteredByConditionLight.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getFilteredByConditionLight.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = payload;
    },
    [getFilteredByConditionLight.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getFilteredByAuction.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getFilteredByAuction.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = payload;
    },
    [getFilteredByAuction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getFilteredBySaleStatus.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getFilteredBySaleStatus.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = payload;
    },
    [getFilteredBySaleStatus.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [auctioneerCarsList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [auctioneerCarsList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.auctioneerCars = payload;
    },
    [auctioneerCarsList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getSoldVehciles.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSoldVehciles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.auctioneerCars = payload;
    },
    [getSoldVehciles.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getNotSoldVehciles.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getNotSoldVehciles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.auctioneerCars = payload;
    },
    [getNotSoldVehciles.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [sortAuctioneerVehicles.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [sortAuctioneerVehicles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.auctioneerCars = payload;
    },
    [sortAuctioneerVehicles.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export default usersSlice.reducer;

export const { setFilterType } = usersSlice.actions;
