import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LogoIcon from '../../../src/assets/logo.png';
import ThemeProvider from 'src/theme';
import { Divider, ListItemButton, Popover, Stack, createTheme, styled } from '@mui/material';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'src/features/auth/authSlice';
import useResponsive from 'src/hooks/useResponsive';
import secureLocalStorage from 'react-secure-storage';

const StyledNavItem = styled((props) => <Button {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  color: '#ffffff',
  position: 'relative',
  textTransform: 'capitalize',
  borderRadius: theme.shape.borderRadius
}));

const pages = [
  { name: 'Home', link: '/' },
  { name: 'About Us', link: '/about' },
  { name: 'Download', link: '/download' },
  { name: 'Pricing', link: '/pricing' },
  { name: 'Contact Us', link: '/contact' }
];

const MENU_OPTIONS = [
  {
    label: 'Profile',
    icon: 'eva:person-fill'
  }
];

function GlobalNavbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTab = useResponsive('up', 'sm');

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogout = () => {
    setAnchorElNav(null);
    setAnchorElUser(null);
    secureLocalStorage.clear();
    dispatch(logout());
  };

  const handleProfile = () => {
    navigate('/dashboard/settings/auctionerProfile');
    setAnchorElUser(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <ThemeProvider>
      <AppBar
        position="static"
        py={2}
        sx={{ backgroundColor: '#333 !important', flex: '0 0 auto' }}
      >
        <Container maxWidth="xl">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              style={{ marginRight: '20px !important' }}
              sx={{
                // mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              <img
                src={LogoIcon}
                style={{ margin: '12px 0', objectFit: 'contain' }}
                width={'100%'}
                height={'56px'}
                alt="logo"
              />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' }
                }}
              >
                {pages.map((page, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate(page?.link);
                    }}
                  >
                    <Typography textAlign="center">{page?.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              <img
                src={LogoIcon}
                width={'100%'}
                height={'56px'}
                alt="logo"
                style={{ objectFit: 'contain' }}
              />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page, i) => (
                <StyledNavItem
                  key={i}
                  component={NavLink}
                  to={page.link}
                  sx={{
                    marginLeft: '10px',
                    '&.active': {
                      bgcolor: 'action.selected',
                      fontWeight: 'fontWeightBold'
                    }
                  }}
                >
                  {page?.name}
                </StyledNavItem>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {isLoggedIn ? (
                <>
                  <Stack
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row'
                    }}
                  >
                    {isTab && (
                      <StyledNavItem
                        component={NavLink}
                        to={'/dashboard/app'}
                        sx={{
                          marginRight: '10px',
                          '&.active': { bgcolor: 'action.selected', fontWeight: 'fontWeightBold' }
                        }}
                      >
                        Dashboard
                      </StyledNavItem>
                    )}
                    <Typography
                      component="div"
                      sx={{ cursor: 'pointer', color: '#ffffff', display: 'flex', alignItems: 'center', gap: '10px' }}
                      onClick={handleOpenUserMenu}
                    >
                      Hi, {userInfo?.firstname}
                      <Avatar
                        src={userInfo?.business_logo ? "https://autocrm.infinitibizsol.com/images/"+userInfo?.business_logo : "/assets/images/avatars/avatar_placeholder.png"}
                        alt="autioneer_photo"
                      />
                    </Typography>

                    <Popover
                      open={Boolean(anchorElUser)}
                      anchorEl={anchorElUser}
                      onClose={handleCloseUserMenu}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      PaperProps={{
                        sx: {
                          p: 0,
                          mt: 1.5,
                          ml: 0.75,
                          width: 180,
                          '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75
                          }
                        }
                      }}
                    >
                      <Stack sx={{ p: 1 }}>
                        {!isTab && (
                          <MenuItem onClick={() => navigate('/dashboard/app')}>Dashboard</MenuItem>
                        )}
                        {MENU_OPTIONS.map((option) => (
                          <MenuItem key={option.label} onClick={handleProfile}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Stack>

                      <Divider sx={{ borderStyle: 'dashed' }} />

                      <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                        Logout
                      </MenuItem>
                    </Popover>
                  </Stack>
                </>
              ) : (
                <>
                  <Button variant="contained" style={{ marginRight: '20px' }}>
                    Request A Demo
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => navigate('/auth/login')}
                    style={{ marginRight: '20px' }}
                  >
                    Login
                  </Button>
                </>
              )}
              {/* <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu> */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}
export default GlobalNavbar;
