import SvgColor from '../../../components/svg-color';
import { useSelector } from 'react-redux';

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const adminSidebar = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('home')
  },
  {
    title: 'opportunities',
    path: '/dashboard/opportunities',
    icon: icon('ic_calendar')
  },
  {
    title: 'settings',
    path: '/dashboard/settings',
    icon: icon('settings')
  }
];
// const adminSidebar = [
//   {
//     title: "dashboard",
//     path: "/dashboard/app",
//     icon: icon("ic_analytics"),
//   },
//   {
//     title: "orders",
//     path: `/dashboard/admin/orders`,
//     icon: icon("ic_cart"),
//   },
//   {
//     title: "products",
//     path: `/dashboard/admin/products`,
//     icon: icon("ic_product"),
//   },
//   {
//     title: "categories",
//     path: `/dashboard/admin/categories`,
//     icon: icon("ic_category"),
//   },
//   {
//     title: "brands",
//     path: `/dashboard/admin/brands`,
//     icon: icon("ic_branding"),
//   },
//   {
//     title: "customers",
//     path: `/dashboard/admin/user`,
//     icon: icon("ic_customer"),
//   },
//   {
//     title: "banners",
//     path: "/dashboard/banner",
//     icon: icon("ic_branding"),
//   },
// ];
export const NavConfig = () => {
  return adminSidebar;
};

export default NavConfig;
