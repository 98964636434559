import PropTypes from 'prop-types';
import { Card, Typography } from '@mui/material';
import { fShortenNumber } from '../../../utils/formatNumber';

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sx: PropTypes.object
};

export default function AppWidgetSummary({ title, total, price, color = 'primary', sx, ...other }) {
  const formattedTotal = total !== undefined && total !== null ? fShortenNumber(total) : null;
  const formattedPrice = price !== undefined && price !== null ? fShortenNumber(price) : null;

  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        // color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx
      }}
      {...other}
    >
      {formattedPrice !== null && <Typography variant="h3">${formattedPrice}</Typography>}
      {formattedTotal !== null && <Typography variant="h3">{formattedTotal}</Typography>}

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}
