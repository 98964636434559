import { Box } from '@mui/material';
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('[ERROR BOUNDARY]', errorInfo);
    console.log('[ERROR BOUNDARY]', error);
    // You can also log the error to an error reporting service
    return {
      error,
      errorInfo
    };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100dvh'}>
          <img src={require('src/assets/error.png')} alt="Something went wrong." />
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
