import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { useSelector } from 'react-redux';

NavSection.propTypes = {
  data: PropTypes.array
};

export default function NavSection({ data = [], ...other }) {
  const { loading, error, userInfo, success } = useSelector((state) => state.auth);
  // const isRole = userInfo?.user_role;

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
        {/* {data.map((item) => {
          if (isRole === "brand") {
            if (
              item.title !== "brands" &&
              item.title !== "customers" &&
              item.title !== "categories" &&
              item.title !== "banners"
            ) {
              return <NavItem key={item.title} item={item} />;
            }
          }
          if (isRole === "admin") {
            if (item.title !== "shipping") {
              return <NavItem key={item.title} item={item} />;
            }
          }
          // else {
          //   return <NavItem key={item.title} item={item} />;
          // }
        })} */}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold'
        }
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
