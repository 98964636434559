import React from 'react';
import { Grid, Typography, Button, Box, Link } from '@mui/material';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { NavLink as RouterLink } from 'react-router-dom';

const backgroundImageBox = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: '50% 50%',
  backgroundImage: "url('/assets/images/cars.jpg')",
  animation: 'zoomIn 4s ease-in',
  transform: 'scale(1.1)',
  zIndex: '-1'
};
const heroBox = {
  width: '100%',
  display: 'flex',
  minHeight: '600px',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden'
};
const heroOverlay = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)' // Adjust opacity as needed
};

const gridContainer = {
  display: 'flex',
  alignItems: 'center',
  maxWidth: '1400px',
  width: '100%',
  padding: '50px',
  zIndex: '100'
};

const aboutUsContainer = {
  width: '100%',
  display: 'flex',
  minHeight: '400px',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '30px 0px 50px 0px'
};

const aboutUsSubtitle = {
  opacity: '0.7',
  paddingBottom: '30px',
  fontSize: '18px'
};

const styles = `
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
`;

const sectionItems = [
  {
    id: 1,
    icon: <SpaceDashboardIcon sx={{ fontSize: 100 }} color="primary" />,
    title: 'Dashboard',
    sentence:
      'Access vital insights into your sales, revenue, and profits. Our dashboard is crafted to provide you with a clear snapshot of your business performance, helping you make informed decisions'
  },
  {
    id: 2,
    title: 'Opportunity Section',
    icon: <PaidOutlinedIcon sx={{ fontSize: 100 }} color="primary" />,
    sentence:
      'Discover which Wholesalers have entrusted you with their vehicles. Receive all necessary vehicle information and utilize our platform to send real-time sales updates back to Wholesalers, enhancing transparency and trust.'
  },
  {
    id: 3,
    title: 'Portfolio Management',
    icon: <BusinessCenterIcon sx={{ fontSize: 100 }} color="primary" />,
    sentence:
      'Tailor your fee structure with precision. Our platform enables you to manage how you charge Wholesalers for your auctioneering services, ensuring clarity and fairness in all your transactions.'
  }
];

const sectionGridContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  minHeight: '500px',
  maxWidth: '1400px',
  width: '100%'
};

const sectionGridItem = {
  backgroundColor: '#f2f0f1',
  textAlign: 'center',
  padding: '30px',
  width: '200px',
  borderRadius: '10px',
  margin: '10px'
};

const LandingPage = () => {
  const date = new Date().getFullYear();

  return (
    <>
      <style>{styles}</style>
      <Box style={{ ...heroBox }}>
        <Box sx={{ ...heroOverlay }}></Box>
        <div style={{ ...backgroundImageBox }}></div>
        <Grid container spacing={6} style={{ ...gridContainer, color: '#ffffff' }}>
          <Grid item xs={12} md={7} style={{ paddingLeft: '0 !important' }}>
            <Typography variant="h3" fontWeight={700} style={{ paddingBottom: '15px' }}>
              Welcome to Auction-Sync
            </Typography>
            <Typography variant="subtitile2">
              The ultimate platform tailored for Auctioneers in the auto auction ecosystem. Whether
              you're managing lanes or representing vehicles at auctions, our comprehensive web and
              mobile applications are your gateway to optimizing your operations and maximizing your
              success.
            </Typography>
            <Button
              component={RouterLink}
              to={'/download'}
              variant="contained"
              color="primary"
              sx={{
                width: '200px',
                fontSize: '16px',
                marginTop: '15px',
                display: 'block',
                textAlign: 'center'
              }}
            >
              Download Now
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* // Section */}

      <Box sx={{ flexGrow: 1, minHeight: '420px', textAlign: 'center' }}>
        <Grid container style={{ ...sectionGridContainer }}>
          {sectionItems.map((item) => (
            <Grid
              item
              xs={12}
              md={3.7}
              minHeight={400}
              key={item.id}
              style={{ ...sectionGridItem }}
            >
              {item.icon}
              <Typography variant="h4">{item?.title}</Typography>
              <Typography>{item.sentence}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* // About US */}

      <Box style={{ ...aboutUsContainer }}>
        <Grid container spacing={6} style={{ ...gridContainer }}>
          <Grid item xs={12} md={5}>
            <img src="/assets/images/graph.png" alt="My Team" width={'100%'} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h3" fontWeight={700} style={{ paddingBottom: '15px' }}>
              Revolutionize Your Auctioneering Approach with Auction-Sync
            </Typography>
            <Typography style={{ ...aboutUsSubtitle }}>
              Leverage our cutting-edge platform to streamline your interactions with Wholesalers,
              ensuring efficient communication and robust partnership management. Auction-Sync is
              more than a tool—it's your partner in driving auction success.
            </Typography>
            <Button variant="contained" color="primary" sx={{ width: '200px', fontSize: '16px' }}>
              CONTACT US
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* // Section2 US */}

      <Box style={{ background: '#f2f0f1', marginBottom: '50px' }}>
        <Grid
          container
          spacing={6}
          style={{
            ...gridContainer,
            textAlign: 'center',
            justifyContent: 'center',
            margin: 'auto'
          }}
        >
          <Typography
            variant="h3"
            fontWeight={700}
            style={{ paddingBottom: '15px', justifyContent: 'center' }}
          >
            Join Us Today
          </Typography>
          <Typography style={{ ...aboutUsSubtitle }}>
            Embrace the future of auto auctions with Auction-Flow and Auction-Sync. Our ecosystem is
            designed to bring Wholesalers and Auctioneers together, fostering a community of
            efficiency, growth, and unparalleled success.
          </Typography>
          <Button variant="contained" color="primary" sx={{ width: '200px', fontSize: '16px' }}>
            Request A Demo
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default LandingPage;
