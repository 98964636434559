import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { baseURL } from 'src/constants/baseURL';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';

const token = secureLocalStorage.getItem('userToken');

export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await axios.post(
        `${baseURL}/auctioneer/login`,
        {
          business_email: email,
          password
        },
        config
      );

      if (data) {
        console.log('user payload', data?.data[0]);
        secureLocalStorage.setItem('userToken', data?.data[0]?.token);
        secureLocalStorage.setItem('expirationTime', data?.data[0]?.expirationTime);
        return data?.data[0];
      }
    } catch (error) {
      if (error.response) {
        console.log('error', error?.response?.data?.message);
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getMe = createAsyncThunk('user/getMe', async ({}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await axios.get(`${baseURL}/users/me`, config);

    if (data) {
      console.log('Get me payload', data);
      return data;
    }
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error?.response?.data?.error?.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getBrandByUser = createAsyncThunk(
  'user/getBrand',
  async ({ id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      };
      const { data } = await axios.get(`${baseURL}/users/${id}`, config);

      if (data) {
        console.log("Get User's Brand payload", data);
        return data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.error?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userDelete = createAsyncThunk('user/delete', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await axios.delete(`${baseURL}/users/${id}`, config);

    if (data) {
      console.log('user payload', data);
      return data;
    }
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error?.response?.data?.error?.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const userEdit = createAsyncThunk(
  'user/edit',
  async ({ id, values }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      };
      const res = await axios.put(`${baseURL}/users/${id}`, values, config);

      if (res?.data) {
        console.log('user edit payload', res?.data);
      }
      return res?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.error?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
