import { styled } from '@mui/material/styles';
import { Container, Stack } from '@mui/material';
import Logo from '../assets/logo1.png';
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <StyledRoot>
      <Container maxWidth="sm">
        <StyledContent>
          <Stack direction="row" alignItems="center" justifyContent="center" marginBottom={8}>
            <img
              src={Logo}
              width={250}
              height={150}
              style={{
                resize: 'contain',
                background: '#f9fafb'
              }}
            />
          </Stack>
          <LoginForm />
        </StyledContent>
      </Container>
    </StyledRoot>
  );
}
