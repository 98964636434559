import { Container, Grid, Card, Typography, Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppWidgetSummary } from 'src/sections/@dashboard/app';

const Settings = () => {
  const navigate = useNavigate();

  const links = [
    {
      label: 'Profile',
      icon: '/assets/icons/navbar/profile.png',
      link: '/dashboard/settings/auctionerProfile'
    },
    {
      label: 'Portfolio',
      icon: '/assets/icons/navbar/portfolio.png',
      link: '/dashboard/settings/portfolio'
    },
    {
      label: 'Activity',
      icon: '/assets/icons/navbar/time.png',
      link: '/dashboard/settings/activity'
    },
    {
      label: 'Privacy Policy',
      icon: '/assets/icons/navbar/privacy-policy.png',
      link: '/dashboard/settings/privacy-policy'
    },
    {
      label: 'Terms & Conditions',
      icon: '/assets/icons/navbar/terms-and-conditions.png',
      link: '/dashboard/settings/terms-and-conditions'
    }
  ];

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4">Settings</Typography>
      </Stack>

      <Grid container spacing={2}>
        {links.map((ele, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Card
              sx={{
                py: 4,
                boxShadow: 0,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={() => navigate(ele.link)}
            >
              <img src={ele.icon} alt="" width={50} />
              <Typography ml={3} variant="h6">
                {ele?.label}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Settings;
