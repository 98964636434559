import { createSlice } from '@reduxjs/toolkit';
import { userDelete, userLogin, userEdit, getMe, getBrandByUser } from './authActions';
import secureLocalStorage from 'react-secure-storage';

const initialState = {
  loading: false,
  userInfo: null,
  brandInfo: null,
  error: null,
  success: false,
  isLoggedIn: secureLocalStorage.getItem('userToken') !== null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.isLoggedIn = false;
      state.userInfo = null;
    },
    updateUserInfo(state, action) {
      state.userInfo = {
        ...state.userInfo,
        ...action.payload
      };
    }
  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isLoggedIn = true;
      state.userInfo = {
        user_id: payload?.auctioneer_id,
        firstname: payload?.firstname,
        lastname: payload?.lastname,
        business_name: payload?.business_name,
        business_email: payload?.business_email,
        business_logo: payload?.business_logo
      };
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getMe.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getMe.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = {
        user_id: payload?.user?._id,
        user_name: payload?.user?.fullname,
        user_email: payload?.user?.email,
        user_role: payload?.user?.role
      };
    },
    [getMe.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [userDelete.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userDelete.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = {
        user_id: payload?.auctioneer_id,
        firstname: payload?.firstname,
        lastname: payload?.lastname,
        business_name: payload?.business_name,
        business_email: payload?.business_email
      };
    },
    [userDelete.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [userEdit.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userEdit.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = {
        user_id: payload?.auctioneer_id,
        firstname: payload?.firstname,
        lastname: payload?.lastname,
        business_name: payload?.business_name,
        business_email: payload?.business_email
      };
    },
    [userEdit.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getBrandByUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getBrandByUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.brandInfo = {
        brand_id: payload?._id,
        brand_name: payload?.brandName,
        branch_code: payload?.branchCode
      };
    },
    [getBrandByUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export default authSlice.reducer;
export const { logout, updateUserInfo } = authSlice.actions;
