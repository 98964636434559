import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Drawer, IconButton } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import LogoIcon from '../../../assets/logo1.png';
import NavConfig from './config';
import MenuIcon from '@mui/icons-material/Menu';

const NAV_WIDTH = 280;

export default function Nav() {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  const isDesktop = useResponsive('up', 'md');

  useEffect(() => {
    if (!isDesktop && open) {
      setOpen(false);
    }
  }, [pathname, isDesktop]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      {/* <Box sx={{ px: 3, py: 3, display: "inline-flex" }}>
                <img src={LogoIcon} width={250} height={120} />
            </Box> */}

      <NavSection data={NavConfig()} style={{ marginTop: '10px' }} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH }
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
              position: 'sticky'
            }
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => setOpen(!open)}
            color="inherit"
            style={{ position: 'absolute' }}
          >
            <MenuIcon />
          </IconButton>

          <Drawer
            open={open}
            onClose={() => setOpen(false)}
            // ModalProps={{
            //     keepMounted: true,
            // }}
            PaperProps={{
              sx: { width: NAV_WIDTH }
            }}
          >
            {renderContent}
          </Drawer>
        </>
      )}
    </Box>
  );
}
